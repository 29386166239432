import { useState, useEffect } from "react";
import NiceModal, { useModal, bootstrapDialog } from "@ebay/nice-modal-react";
import BootstrapModal from "react-bootstrap/Modal";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import React from "react";
import { toast } from "react-toastify";
import { instance } from "../axios";
import { useNavigate, useParams } from "react-router-dom";


import Form from 'react-bootstrap/Form';
import { Button } from 'react-bootstrap';
import Select, { StylesConfig } from "react-select";

const AddPhoneBook = NiceModal.create(
  ({ title, subtitle, action, bgColor, note = "", data }) => {
    const [input, setInput] = useState(note);
    const [state, setState] = useState({});
    const modal = useModal();
    const schema = yup.object().shape({
      name: yup.string().required("Enter group name"),

    });
    const {
      register,
      handleSubmit,
      control,
      formState: { errors },
      reset,
    } = useForm({
      resolver: yupResolver(schema),
    });
    const onSubmitHandler = async (data) => {
      SaveTag();
      //reset();
    };
    var SaveTag = (e) => {
      setState({ ...state, loader: true });
      var form = document.querySelector("#grpformu");
      var data = new FormData(form);

      instance({
        method: "post",
        url: "/update_group.php",
        data: data,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then(function (response) {
          setState({ ...state, loader: false });
          if (response.data.status == "error") {
            toast(response.data.msg, { type: "error" });
          }
          if (response.data.status == "success") {
            toast(response.data.msg, { type: "success" });
            modal.resolve({ resolved: true, name:response.data.name });
            modal.hide();
          }
        })
        .catch(function (response) { });
    };
    useEffect(() => {

      if (data) {
        reset(data)
      }


    }, [data]);


    let navigate = useNavigate();

    const handleHide = () => {
      modal.hide();

    }
    return (
      <BootstrapModal {...bootstrapDialog(modal)} onHide={() => handleHide()}>
        <BootstrapModal.Header closeButton>
          <BootstrapModal.Title>Phonebook</BootstrapModal.Title>
        </BootstrapModal.Header>

        <BootstrapModal.Body>
          <form id="grpformu" onSubmit={handleSubmit(onSubmitHandler)}>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Group Name</Form.Label>
              <Form.Control type="text" name="name"  {...register("name")} />
              <Form.Text className="text-danger">
                {errors.name?.message}
              </Form.Text>

            </Form.Group>
            {data && (
              <input type="hidden" value={data.id} name="id" {...register("id")} />
            )}
            <Button variant="primary" type="submit">
              Save
            </Button>
          </form>
        </BootstrapModal.Body>

      </BootstrapModal>
    );
  }
);

export default AddPhoneBook;
