import React, { useState, useEffect } from "react";

import { useNavigate, Link } from "react-router-dom";


export default function Home() {

  const [state, setState] = useState({});


  return (
    <div>
      <div className="row mt-4">
       <h2>Welcome</h2>
      </div>

    </div>
  );
}
