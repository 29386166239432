import React from "react";
import ReactDOM from "react-dom/client";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import NiceModal from '@ebay/nice-modal-react';

import {
  Routes,
  Route,
  BrowserRouter,

} from "react-router-dom";
import App from "./App";




const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  
 
  <BrowserRouter>
  <ToastContainer hideProgressBar={true} theme="colored"/>
  <NiceModal.Provider>

<App/>
</NiceModal.Provider>

  </BrowserRouter>
  


);