import { useState, useEffect } from "react";
import NiceModal, { useModal, bootstrapDialog } from "@ebay/nice-modal-react";
import BootstrapModal from "react-bootstrap/Modal";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import React from "react";
import { toast } from "react-toastify";
import { instance } from "../axios";
import { useNavigate, useParams } from "react-router-dom";
import { UiSelect, UiDatePicker, UiRSelect, UiToggle, titleCase } from "../ui";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { useStore } from "../state.js";


const AddContact = NiceModal.create(
  ({ title, subtitle, action, bgColor, note = "", data }) => {
    const [input, setInput] = useState(note);
    const [state, setState] = useState({ count: 0 });
    const [entry, setEntry] = useState([]);
    const modal = useModal();
    const zstate = useStore((state) => state.groups);
    const mstate = useStore((state) => state.machines);
    const setZState = useStore((state) => state.setGroups);
    const schema = yup.object().shape({
      name: yup.string().required("Enter name"),
      mobile: yup.string().nullable().when('mobile', {
        is: (value) => value?.length,
        then: (rule) => rule.matches(/^[0-9]+$/, "Enter 10 digit mobile number")
          .min(10, "Enter 10 digit mobile number")
          .max(10, "Enter 10 digit mobile number"),
      }),

      mobile2: yup.string().nullable().when('mobile2', {
        is: (value) => value?.length,
        then: (rule) => rule.matches(/^[0-9]+$/, "Enter 10 digit mobile number")
          .min(10, "Enter 10 digit mobile number")
          .max(10, "Enter 10 digit mobile number"),
      }),

      machine: yup.array().of(
        yup.object().shape({
          name: yup.string().required("Required"),
          qty: yup.string().required("Required")
        })
      ),
      groups: yup.array().min(1, 'Select group').required('Select group'),

    }, [["mobile2", "mobile2"], ["mobile", "mobile"]]);
    const {
      register,
      handleSubmit,
      control,
      unregister,
      setValue,
      formState: { errors },
      reset,
    } = useForm({
      resolver: yupResolver(schema),
    });
    const onSubmitHandler = async (data) => {
      SaveTag(data);
      //reset();
    };
    var SaveTag = (e) => {
      setState({ ...state, loader: true });

      instance({
        method: "post",
        url: "/update_contact.php",
        data: e,
        headers: { "Content-Type": "application/json" },
      })
        .then(function (response) {
          setState({ ...state, loader: false });
          if (response.data.status == "error") {
            toast(response.data.msg, { type: "error" });
          }
          if (response.data.status == "success") {
            toast(response.data.msg, { type: "success" });
            modal.resolve({ resolved: true });
            modal.hide();
          }
        })
        .catch(function (response) { });
    };
    useEffect(() => {

      if (data) {
        //)
        reset(data)
        if (data.machines) {
          setEntry(data.machines)
          setState({ ...state, count: data.machines.length })
        }
      }


    }, [data]);


    let navigate = useNavigate();

    const handleHide = () => {
      modal.hide();
    }

    const insertEntry = () => {
      setState({ ...state, count: state.count + 1 })
      setEntry([...entry, { id: state.count }])


    }
    const deleteEntry = (id, idx) => {
      var aa = entry;
      unregister(`machine.[${idx}].name`);
      unregister(`machine.[${idx}].name`);
      unregister(`machine.[${idx}]`);
      var filtered = aa.filter(function (value, index, arr) {
        return value.id !== id;
      });
      console.log(filtered)
      setEntry(filtered);
    }


    return (
      <BootstrapModal {...bootstrapDialog(modal)} onHide={() => handleHide()}>
        <BootstrapModal.Header closeButton>
          <BootstrapModal.Title>Add Contact</BootstrapModal.Title>
        </BootstrapModal.Header>

        <BootstrapModal.Body>
          <form id="contactform" onSubmit={handleSubmit(onSubmitHandler)}>
            <Form.Group className="mb-3" >
              <Form.Label>Name</Form.Label>
              <Form.Control type="text" name="name"   {...register("name", { onBlur: (e) => setValue('name', titleCase(e.target.value)) })} />
              <Form.Text className="text-danger">
                {errors.name?.message}
              </Form.Text>
            </Form.Group>

            <div className="row">

              <div className="col-6">
                <UiToggle id="active" name="is_active" title="Business Active" {...register("is_active")} />
              </div>
              <div className="col-6">
              <UiToggle id="send_msg" name="send_msg" title="Send Message" {...register("send_msg")} />
              </div>
            </div>


            <div className="row">
              <div className="col-md-6">
                <Form.Group className="mb-3" >
                  <Form.Label>Contact Person 1</Form.Label>
                  <Form.Control type="text" name="name1" {...register("name1", { onBlur: (e) => setValue('name1', titleCase(e.target.value)) })} />
                  <Form.Text className="text-danger">
                    {errors.name1?.message}
                  </Form.Text>
                </Form.Group>
              </div>
              <div className="col-md-6">
                <Form.Group className="mb-3" >
                  <Form.Label>Mobile No. 1</Form.Label>
                  <Form.Control type="number" name="mobile"  {...register("mobile")} />
                  <Form.Text className="text-danger">
                    {errors.mobile?.message}
                  </Form.Text>
                </Form.Group>
              </div>

            </div>

            <div className="row">

              <div className="col-6">
                <Controller
                  name="dob"
                  control={control}
                  render={({
                    field: { onChange, onBlur, value, name, ref },
                    fieldState: { invalid, isTouched, isDirty, error },
                    formState,
                  }) => (
                    <UiDatePicker
                      label="Date of Birth 1"
                      onChange={onChange}
                      default_val={value}
                      message={errors.dob?.message}
                      dateFormat="dd-MM-yyyy"
                      ref={ref}
                      name={name}
                    />
                  )}
                />
              </div>
              <div className="col-6">
                <Controller
                  name="wedding"
                  control={control}
                  render={({
                    field: { onChange, onBlur, value, name, ref },
                    fieldState: { invalid, isTouched, isDirty, error },
                    formState,
                  }) => (
                    <UiDatePicker
                      label="Wedding anniversary 1"
                      onChange={onChange}
                      default_val={value}
                      message={errors.wedding?.message}
                      dateFormat="dd-MM-yyyy"
                      ref={ref}
                      name={name}
                    />
                  )}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-md-6">
                <Form.Group className="mb-3" >
                  <Form.Label>Contact Person 2</Form.Label>
                  <Form.Control type="text" name="name2"  {...register("name2", { onBlur: (e) => setValue('name2', titleCase(e.target.value)) })} />
                  <Form.Text className="text-danger">
                    {errors.name2?.message}
                  </Form.Text>
                </Form.Group>
              </div>
              <div className="col-md-6">
                <Form.Group className="mb-3" >
                  <Form.Label>Mobile No. 2</Form.Label>
                  <Form.Control type="number" name="mobile2"  {...register("mobile2")} />
                  <Form.Text className="text-danger">
                    {errors.mobile2?.message}
                  </Form.Text>
                </Form.Group>
              </div>

            </div>

            <div className="row">

              <div className="col-6">
                <Controller
                  name="dob2"
                  control={control}
                  render={({
                    field: { onChange, onBlur, value, name, ref },
                    fieldState: { invalid, isTouched, isDirty, error },
                    formState,
                  }) => (
                    <UiDatePicker
                      label="Date of Birth 2"
                      onChange={onChange}
                      default_val={value}
                      message={errors.dob2?.message}
                      dateFormat="dd-MM-yyyy"
                      ref={ref}
                      name={name}
                    />
                  )}
                />
              </div>
              <div className="col-6">
                <Controller
                  name="wedding2"
                  control={control}
                  render={({
                    field: { onChange, onBlur, value, name, ref },
                    fieldState: { invalid, isTouched, isDirty, error },
                    formState,
                  }) => (
                    <UiDatePicker
                      label="Wedding anniversary 2"
                      onChange={onChange}
                      default_val={value}
                      message={errors.wedding2?.message}
                      dateFormat="dd-MM-yyyy"
                      ref={ref}
                      name={name}
                    />
                  )}
                />
              </div>
            </div>

            <Form.Group className="mb-3" >
              <Form.Label>City</Form.Label>
              <Form.Control type="text" name="city"  {...register("city")} />
              <Form.Text className="text-danger">
                {errors.city?.message}
              </Form.Text>
            </Form.Group>


            <Form.Group className="mb-3" >
              <Form.Label>Address</Form.Label>
              <Form.Control type="text" name="address"  {...register("address")} />
              <Form.Text className="text-danger">
                {errors.address?.message}
              </Form.Text>
            </Form.Group>





            <Controller
              name="groups[]"
              control={control}
              render={({ field }) => (

                <UiRSelect
                  {...field}
                  options={zstate}
                  className="ss"
                  isMulti
                  message={errors.groups?.message}
                  label="Group"
                />

              )}
            />

            <b>Machines</b>
            <div className="border rounded p-3 mb-3">
              {entry && entry.length > 0 && entry.map((value, i) => (
                <div key={i} className="d-flex mb-2">
                  <div key={i} className="flex-grow-1">
                    <UiSelect
                      name={`machine[${i}].name`}
                      message={errors.machine?.[i]?.name?.message}
                      defaultValue={value.machine}
                      options={mstate}
                      {...register(`machine.[${i}].name`)}
                    />
                  </div>
                  <div className="ps-3 col-3"><Form.Control
                    placeholder="Qty"
                    type="number"
                    className="mb-0"
                    name={`machine[${i}].qty`}

                    defaultValue={value.qty}
                    {...register(`machine.[${i}].qty`)}
                  />
                    <Form.Text className="text-danger">
                      {errors.machine?.[i]?.qty?.message}
                    </Form.Text>
                  </div>
                  <button className="btn btn-outline-danger ms-3" style={{ height: "34px" }} onClick={() => deleteEntry(value.id, i)} >Delete</button>
                </div>
              ))}

              <div className="text-center">
                <div className="btn btn-secondary" onClick={() => insertEntry()} ><span className="sicon">+</span> Add</div>
              </div>
            </div>
            {data && (<input type="hidden" value={data.id} name="id" {...register("id")} />)}
            <Button variant="primary" type="submit">
              Submit
            </Button>
          </form>
          <p className="mt-2">Created by: {data && data.created_by} | Created On: {data && data.created_on}</p>
        </BootstrapModal.Body>

      </BootstrapModal>
    );
  }
);

export default AddContact;
