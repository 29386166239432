import create from 'zustand'

export const useBStore = create((set) => ({
  bears: 0,
  increasePopulation: () => set((state) => ({ bears: state.bears + 1 })),
  removeAllBears: () => set({ bears: 0 }),
}))

export const useStore = create(set => ({
  auth: false,
  senderid: '',
  machines: [],
  groups: [],
  setMachines: (text) =>
    set((state) => ({
      machines: text
    })),
  setGroups: (text) =>
    set((state) => ({
      groups: text
    })),
  setSenderid: (text) =>
    set((state) => ({
      senderid: text
    })),
  setAuth: (text) =>
    set((state) => ({
      auth: text
    })),
}));