import React, { forwardRef, useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Select, { StylesConfig } from "react-select";
import CreatableSelect from 'react-select/creatable';
import { components } from 'react-select';
import { AiOutlineSearch, AiOutlinePlus } from "react-icons/ai";
import { MdOutlineClose } from "react-icons/md";
import DatePicker from "react-datepicker";
import { instance } from "./axios";
import { format, isValid, parse } from 'date-fns';
import { Link, useLocation, useNavigate } from "react-router-dom";

import "react-datepicker/dist/react-datepicker.css";
import { GoPlus } from "react-icons/go";
import AsyncSelect from 'react-select/async';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

import NiceModal, { useModal } from "@ebay/nice-modal-react";


import {AiOutlineFileSearch} from "react-icons/ai";
import { BsImageFill, BsArrowRightCircle, BsFillExclamationTriangleFill } from "react-icons/bs";
import { MdEdit, MdClose, MdArrowForwardIos } from "react-icons/md";





export const UiToggle = forwardRef(({ id, name, pclass, loading, title, ...props }, ref) => {
  return (
    <>
      {loading ? (
        <span className="spinner-border spinner-border-sm"></span>
      ) : (

        <div className={`form-check form-switch ${pclass ? '' : 'mb-4'}`}>
          <input
            className="form-check-input"
            type="checkbox"
            role="switch"
            name={name}
            ref={ref}
            id={id}
            {...props}
          />
          <label className="form-check-label" htmlFor={id}>
            {title}
          </label>
        </div>

      )}
    </>
  );
});
export const OverlayLoader = forwardRef(
  (
    {
      visible,
      ...props
    },
    ref
  ) => {
    return (
      <div className={`overlayloader ${visible == true ? '' : 'd-none'}`} >
        <span className="spinner-border align-middle ms-2 text-primary" style={{ width: '3rem', height: '3rem' }}></span>


      </div>
    );
  }
);

export const titleCase = (str) => {
  return str
    .toLowerCase()
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
}
export const UiInput = forwardRef(
  (
    {
      type,
      name,
      parentClass,
      noerror,
      label,
      required,
      placeholder,
      className,
      max_width,
      lcol,
      icol,
      icon,
      message,
      prefix,
      showicon,
      footer_text,
      ...props
    },
    ref
  ) => {
    const [showpass, setShowpass] = useState(false);
    return (
      <div className={`${parentClass ? parentClass : "mb-4"} ${lcol ? "row" : ""}`}>
        {label && (
          <label
            className={`form-label ${lcol ? "pt-sm-2 col-sm-" + lcol : ""
              }`}
          >
            {label}{" "}
            <span className="text-red font-bold text-lg">
              {required && "*"}
            </span>
          </label>
        )}

        <div className={`${icol && "col-sm-" + icol}`} style={max_width && { maxWidth: max_width }}>
          <div className={`position-relative ${prefix && 'input-group'}`}>
            {prefix && (<span className="input-group-text">{prefix}</span>)}
            {icon == 'inr' && <div className="fieldicon">
              ₹
            </div>}
            {icon == 'percent' && <div className="fieldicon">
              %
            </div>}

            <input
              name={name}
              ref={ref}
              type={showpass == true ? "text" : type}
              className={`form-control form-control-solid ${message ? "is-invalid" : ""} ${icon && 'input_i'} ${showicon && 'input_suf'} ${className}`}
              placeholder={placeholder}
              autoComplete="off"
              {...props}
            />
            
            {footer_text &&
              <div className="form-text">{footer_text}</div>
            }
            {!noerror && message && <div className="invalid-feedback">{message}</div>}
          </div>
        </div>
      </div>
    );
  }
);



export const UiButton = forwardRef(
  ({ type, name, title, className, icon, loading, disabled, hideloadmsg, ...props }, ref) => {
    return (
      <>
        <button
          name={name}
          ref={ref}
          type={type}
          className={`btn ${icon && 'd-inline-flex'} ${className}`}
          {...props}
          disabled={((loading) || (disabled == 1)) ? "disabled" : ""}
        >
          {loading ? (
            <span className="indicator-progress">
              {hideloadmsg ? '' : 'Please wait...  '}
              <span className="spinner-border spinner-border-sm align-middle"></span>
            </span>
          ) : (
            <>
              {icon == 'add' && <span className="sicon"><GoPlus /></span>}
              {icon == 'go' && <span className="sicon"><BsArrowRightCircle /></span>}
              {icon == 'edit' && <span className="sicon"><MdEdit /></span>}
              {icon == 'delete' && <span className="sicon"><MdOutlineClose /></span>}
              {title && <span className={icon && 'ms-2'} >{title}</span>}
            </>
          )}
        </button>
      </>
    );
  }
);



export function PageHeader(props) {
  const location = useLocation();
  let navigate = useNavigate();
  const { link, parent, stacked, noBack, title, ...other } = props;
  const doesAnyHistoryEntryExist = location.key !== "default";


  const handleBack = () => {
    if (doesAnyHistoryEntryExist == true) {
      navigate(-1)
    } else {
      navigate("/")
    }
  }
  return (
    <div className={`${stacked == true ? 'd-lg-flex align-items-center' : 'd-flex align-items-center'} my-3`}>
      <div className={`${stacked == true ? 'flex-grow-1' : 'flex-grow-1'}`}><h1 className="d-flex align-items-sm-center flex-sm-row flex-column">
        <div className="d-flex parentpd">{parent && (<><Link className="text-decoration-none" to={link}>{parent}</Link> <span className="sicon mx-1 d-none d-sm-flex"><MdArrowForwardIos /></span></>)} </div><span>{title}</span></h1></div>

      <div className="d-inline-flex" {...other} />
    </div>
  );
}

export const TableZero = forwardRef(
  (
    {
      title,
      ...props
    },
    ref
  ) => {
    return (
      <div className="mb-3 text-center text-muted" >
        <span className="fs-1 d-block text-muted"><AiOutlineFileSearch /></span>
        There are no {title}
      </div>
    );
  }
);

export const BgLoader = forwardRef(
  (
    {
      ...props
    },
    ref
  ) => {
    return (
      <div className="d-flex align-items-center w-100 justify-content-center bgloader my-5" >
        <div class="spinner-border text-primary" role="status">
          <span class="sr-only"></span>
        </div>
      </div>
    );
  }
);


export const PageError = forwardRef(
  (
    {
      msg,
      code,
      ...props
    },
    ref
  ) => {
    return (
      <div className="text-center mt-5">
        <span className="text-warning" style={{ fontSize: '60px' }}><BsFillExclamationTriangleFill /></span>
        <h2>{code == 4 ? 'Not Found!' : msg}</h2>

      </div>

    );
  }
);

export const UiRSelect = forwardRef(
  (
    {

      label,
      required,
      placeholder,
      className,
      lcol,
      icol,
      message,
      addnew,
      handleNew,
      ...props
    },
    ref
  ) => {
    const MenuList = (
      props
    ) => {
      return (
        <components.MenuList {...props}>

          {props.children}
          <div className="p-2">
            <button className="btn btn-outline-secondary w-100" onClick={() => handleNew()}><span className="sicon"><GoPlus /></span> Add New</button>
          </div>
        </components.MenuList>
      );
    };
    return (

      <div className={`mb-4 ${lcol ? "row" : ""}`}>
        <label
          className={`form-label fs-6 fw-bolder text-dark ${lcol ? "col-sm-" + lcol : ""
            }`}
        >
          {label}{" "}
          <span className="text-red-500 font-bold text-lg">
            {required && "*"}
          </span>
        </label>
        <div className={icol ? "col-sm-" + icol : ""}>
          <Select
            ref={ref}
            components={addnew && { MenuList }}
            className={`rselect ${message ? "is-invalid" : ""
              } ${className}`}

            {...props}
          />
          {message && <div className="invalid-feedback">{message}</div>}
        </div>
      </div>
    );
  }
);



export const UiTagInput = forwardRef(
  (
    {

      label,
      required,
      placeholder,
      className,
      lcol,
      icol,
      message,
      ...props
    },
    ref
  ) => {
    return (
      <div className={`mb-4 ${lcol ? "row" : ""}`}>
        <label
          className={`form-label  ${lcol ? "col-sm-" + lcol : ""
            }`}
        >
          {label}{" "}
          <span className="text-red-500 font-bold text-lg">
            {required && "*"}
          </span>
        </label>
        <div className={icol ? "col-sm-" + icol : ""}>
          <CreatableSelect
            isClearable
            components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
            isMulti
            noOptionsMessage={() => null}
            ref={ref}

            className={`rselect ${message ? "is-invalid" : ""
              } ${className}`}

            {...props}


          />
          {message && <div className="invalid-feedback">{message}</div>}
        </div>
      </div>
    );
  }
);


export const UiSelect = forwardRef(({ icol, className, message, parentClass, lcol, options, label, required, default_val, name, noerror, title, ...props }, ref) => {
  return (
    <div className={`${parentClass ? { parentClass } : "mb-4"} ${lcol ? "row" : ""}`}>
      {label && (
        <label
          className={`form-label ${lcol ? "col-sm-" + lcol : ""
            }`}
        >
          {label}{" "}
          <span className="text-red-500 font-bold text-lg">
            {required && "*"}
          </span>
        </label>
      )}
      <div className={icol ? "col-sm-" + icol : ""}>
        <select
          className={`form-select ${message ? "is-invalid" : ""
            } ${className}`}
          name={name}
          ref={ref}
          {...props}>
          <option></option>
          {options[0].value ? options.map((option, i) => (
            <option value={option.value} key={i}>{option.label}</option>
          )) :
            options.map((option, i) => (
              <option value={option} key={i}>{option}</option>
            ))
          }
        </select>
        {!noerror && message && <div className="invalid-feedback">{message}</div>}

      </div>
    </div>
  );
});


export function UiTFind(props) {
  const { setFilterText, filterText, heading } = props;
  return (
    <div className="input-group flex-nowrap width25">
      <span className="input-group-text" ><AiOutlineSearch /></span>
      {filterText && (<span className="clearbtn" onClick={() => setFilterText('')}><MdOutlineClose /></span>)}
      <input className="form-control" onChange={e => setFilterText(e.target.value)} value={filterText} />

    </div>
  );
}


export const UiDatePicker = forwardRef(({ icol, className, dateFormat, message, lcol, label, required, default_val, name, onChange, title, ...props }, ref) => {
  return (
    <div className={`mb-4 ${lcol ? "row" : ""}`}>
      <label
        className={`form-label ${lcol ? "col-sm-" + lcol : ""
          }`}
      >
        {label}{" "}
        <span className="text-red-500 font-bold text-lg">
          {required && "*"}
        </span>
      </label>
      <div className={icol ? "col-sm-" + icol : ""}>

        <DatePicker
          className={`form-control ${message ? "is-invalid" : ""
            } ${className}`}
          onChange={(e) => onChange(format(e, dateFormat))} // send value to hook form
          selected={default_val && parse(default_val, dateFormat, new Date())}
          name={name}
          dateFormat={dateFormat}
          ref={ref}
          {...props}
        />
        {message && <div className="invalid-feedback d-block">{message}</div>}

      </div>
    </div>
  );
});



export const UiTextArea = forwardRef(
  (
    {
      type,
      name,
      parentClass,
      noerror,
      label,
      required,
      placeholder,
      className,
      lcol,
      icol,
      message,
      ...props
    },
    ref
  ) => {
    return (
      <div className={`${parentClass ? { parentClass } : "mb-4"} ${lcol ? "row" : ""}`}>
        {label && (
          <label
            className={`form-label ${lcol ? "col-sm-" + lcol : ""
              }`}
          >
            {label}{" "}
            <span className="text-red-500 font-bold text-lg">
              {required && "*"}
            </span>
          </label>
        )}
        <div className={icol ? "col-sm-" + icol : ""}>
          <textarea
            name={name}
            ref={ref}
            type={type}
            className={`form-control ${message ? "is-invalid" : ""
              } ${className}`}
            placeholder={placeholder}
            autoComplete="off"
            {...props}
          />
          {!noerror && message && <div className="invalid-feedback">{message}</div>}
        </div>
      </div>
    );
  }
);



export const UiFindSelect = forwardRef(
  (
    {

      label,
      required,
      placeholder,
      className,
      lcol,
      icol,
      message,
      ...props
    },
    ref
  ) => {
    return (
      <div className={`mb-4 ${lcol ? "row" : ""}`}>
        <label
          className={`form-label fs-6 fw-bolder text-dark ${lcol ? "col-sm-" + lcol : ""
            }`}
        >
          {label}{" "}
          <span className="text-red-500 font-bold text-lg">
            {required && "*"}
          </span>
        </label>
        <div className={icol ? "col-sm-" + icol : ""}>
        <AsyncSelect
                  cacheOptions
                  
                  defaultOptions
                  noOptionsMessage={() => 'Type to search'}

           
            isClearable
            components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
            ref={ref}

            className={`rselect ${message ? "is-invalid" : ""
              } ${className}`}

            {...props}


          />
          {message && <div className="invalid-feedback">{message}</div>}
        </div>
      </div>
    );
  }
);