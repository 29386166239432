import React, { useState, useEffect, useReducer } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { UiTextArea, UiTagInput } from "../../ui";

import * as yup from "yup";
import { useNavigate, Link } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import DataTable from 'react-data-table-component';
import { AiOutlineClose, AiOutlineSave } from "react-icons/ai";
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { toast } from "react-toastify";
import { instance } from "../../axios";


export default function ChangePassword() {

  const [state, setState] = useState([]);
  const [edit, setEdit] = useState([]);

  const schema = yup.object().shape({
    cur_password: yup.string().required("Enter your current password"),
    password: yup
      .string()
      .required("Please enter password")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
      ),
    password1: yup
      .string()
      .oneOf(
        [yup.ref("password"), null],
        "The password and its confirm are not the same"
      ),

  });
  const {
    register,
    handleSubmit,
    control,
    unregister,
    watch,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });
  const onSubmitHandler = async (data) => {
    SaveTag();
    //reset();
  };
  var SaveTag = (e) => {
    setState({ ...state, loader: true });
    var form = document.querySelector("form");
    var data = new FormData(form);

    instance({
      method: "post",
      url: "/change_password.php",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then(function (response) {
        setState({ ...state, loader: false });
        if (response.data.status == "error") {
          toast(response.data.msg, { type: "error" });
        }
        if (response.data.status == "success") {
          toast(response.data.msg, { type: "success" });
          reset()

        }
      })
      .catch(function (response) { });
  };






  return (
    <div className="pt-4">
      <h2 className="mb-3">Change Password</h2>
      <div className="p-3 rounded bg-white">

        <form onSubmit={handleSubmit(onSubmitHandler)}>

        <Form.Group className="mb-3" >
                <Form.Label>Current Password</Form.Label>
                <Form.Control type="password" name="cur_password"  {...register("cur_password")} />
                <Form.Text className="text-danger">
                  {errors.cur_password?.message}
                </Form.Text>
              </Form.Group>
        <Form.Group className="mb-3" >
                <Form.Label>Password</Form.Label>
                <Form.Control type="password" name="password"  {...register("password")} />
                <Form.Text className="text-danger">
                  {errors.password?.message}
                </Form.Text>
              </Form.Group>
        <Form.Group className="mb-3" >
                <Form.Label>Confirm Password</Form.Label>
                <Form.Control type="password" name="password1"  {...register("password1")} />
                <Form.Text className="text-danger">
                  {errors.password1?.message}
                </Form.Text>
              </Form.Group>
      



            <Button variant="primary" type="submit">
              Save
            </Button>
          
        </form>
      </div>

    </div>
  );
}
