import { useState, useEffect } from "react";
import NiceModal, { useModal, bootstrapDialog } from "@ebay/nice-modal-react";
import BootstrapModal from "react-bootstrap/Modal";
import { useForm, Controller } from "react-hook-form";
import { UiDatePicker, UiTextArea, UiRSelect } from "../ui";

import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import React from "react";
import { toast } from "react-toastify";
import { instance } from "../axios";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";


import Form from 'react-bootstrap/Form';
import { Button } from 'react-bootstrap';
import Select, { StylesConfig } from "react-select";

const Templates = NiceModal.create(
  ({ title, subtitle, action, bgColor, note = "", data }) => {
    const [input, setInput] = useState(note);
    const [state, setState] = useState({});

    const [menu, setMenu] = useState(0);

    const modal = useModal();

    const schema = yup.object().shape({
      content: yup.string().required("Required"),
      dltid: yup.string().required("Required"),
  
    });
    const {
      register,
      handleSubmit,
      control,
      unregister,
      watch,
      getValues,
      formState: { errors },
      reset,
    } = useForm({
      resolver: yupResolver(schema),
    });
    const onSubmitHandler = async (data) => {

          SaveTag();
  
  
    };
    var SaveTag = (e) => {
      var form = document.querySelector("#savetempform");
      var data = new FormData(form);
  
      instance({
        method: "post",
        url: "/update_template.php",
        data: data,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then(function (response) {
          if (response.data.status == "error") {
            toast(response.data.msg, { type: "error" });
          }
          if (response.data.status == "success") {
            toast(response.data.msg, { type: "success" });
            reset()
            fetchData()
  
          }
        })
        .catch(function (response) { });
    };


    let navigate = useNavigate();

    const handleHide = () => {
      modal.hide();

    }


    const fetchData = () => {
  
  
  
      instance
        .get("/templates.php")
        .then(function (response) {
          setState(response.data)
        })
        .catch(function (error) { })
        .then(function () { });
    }
  
  
    useEffect(() => {
      fetchData()
  
    }, []);


    const showhideAdd = (e) => {
      console.log(menu)
      if (menu == 1) {
        setMenu(0);
      } else {
        setMenu(1);
      }
    }



    const handleDelete = (id) => {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.isConfirmed) {
          instance
            .get("/delete_template.php?id=" + id)
            .then(function (response) {
              if (response.data.status == 'success') {
  
                toast(response.data.msg, { type: "success" });
fetchData()  
                
              } else {
                toast(response.data.msg, { type: "error" });
              }
  
  
            })
  
        }
      })
  
  
    }


    const selectTemp = (v) => {
      modal.resolve({ resolved: true, temp:v });
      modal.hide();
    }
    return (
      <BootstrapModal {...bootstrapDialog(modal)} onHide={() => handleHide()}>
        <BootstrapModal.Header closeButton>
          <BootstrapModal.Title>Templates</BootstrapModal.Title>
        </BootstrapModal.Header>

        <BootstrapModal.Body>
        <input type="checkbox" className="btn-check" id="btn-check-outlined1"  onChange={(e)=>showhideAdd(e)} />
<label className="btn btn-outline-primary" for="btn-check-outlined1">Add Template</label>
          {menu == 1 && (
            <div className="border shadow mb-3 rounded p-3 mt-3">
                    <form id="savetempform" onSubmit={handleSubmit(onSubmitHandler)}>

            <UiTextArea
                label="SMS Content"
                name="content"
                message={errors.content?.message}
                {...register("content")}
              />
               <Form.Group className="mb-3" >
                <Form.Label>DLT ID</Form.Label>
                <Form.Control type="text" name="dltid"  {...register("dltid")} />
                <Form.Text className="text-danger">
                  {errors.dltid?.message}
                </Form.Text>
              </Form.Group>
              <Button variant="primary" type="submit">
                Save
              </Button>
              </form>
            </div>
          )}
          {state.templates && state.templates.length > 0 ? (
<>
{state.templates.map((val,i)=>(
  <div key={i} className="border rounded p-3 mt-3 d-flex">
    <div><input type="radio" className="form-check-input me-3" onChange={()=>selectTemp(val)}></input></div>
    <div className="flex-grow-1">{val.content}<div className="d-flex border-top pt-2 mt-2"><span className="flex-grow-1 w-100 d-block">DLT ID: {val.dltid}</span><a className="btn btn-sm btn-danger" onClick={()=>handleDelete(val.id)} >Delete</a></div></div>

  </div>
))}
</>
          ) : (
          <p>Not found</p>
          )} 
        
        </BootstrapModal.Body>

      </BootstrapModal>
    );
  }
);

export default Templates;
