import React, { useState, useEffect, useReducer } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { UiTextArea, UiTagInput, UiSelect } from "../../ui";
import { useStore } from "../../state.js";
import * as yup from "yup";
import { useNavigate, Link } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import DataTable from 'react-data-table-component';
import { AiOutlineClose, AiOutlineSave } from "react-icons/ai";
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { toast } from "react-toastify";
import { instance } from "../../axios";


export default function Settings() {

  const [state, setState] = useState([]);
  const [edit, setEdit] = useState([]);
  const setMState = useStore((state) => state.setMachines);
  const setSender = useStore((state) => state.setSenderid);
  const schema = yup.object().shape({
    senderid: yup.string().nullable().required("Enter sender id (6 Characters)").min(6, "Enter sender id (6 Characters)").max(6, "Enter sender id (6 Characters)"),
    machines: yup.array().min(1, 'Enter machines').required('Enter machines'),

  });
  const {
    register,
    handleSubmit,
    control,
    watch,
    unregister,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });
  const onSubmitHandler = async (data) => {
    SaveTag(data);
    //reset();
  };
  const sid = watch('senderid');
  var SaveTag = (e) => {


    instance({
      method: "post",
      url: "/update_settings.php",
      data: e,
      headers: { "Content-Type": "application/json" },
    })
      .then(function (response) {

        if (response.data.status == "error") {
          toast(response.data.msg, { type: "error" });
        }
        if (response.data.status == "success") {
          toast(response.data.msg, { type: "success" });
          setMState(response.data.machines)
          setSender(sid)
        }
      })
      .catch(function (response) { });
  };
  const fetchData = () => {

    instance
      .get("/settings.php")
      .then(function (response) {
        setState(response.data)
        reset(response.data.settings)
      })
      .catch(function (error) { })
      .then(function () { });
  }
  useEffect(() => {
    fetchData()

  }, []);


  return (
    <div className="pt-4">
      <h2 className="mb-3">Settings</h2>
      <div className="p-3 rounded bg-white">

        <form onSubmit={handleSubmit(onSubmitHandler)}>

          <Form.Group className="mb-3" >
            <Form.Label>Sender ID (SMS Header)</Form.Label>
            <Form.Control type="text" name="senderid"  {...register("senderid")} />
            <Form.Text className="text-danger">
              {errors.senderid?.message}
            </Form.Text>
          </Form.Group>




          <Controller
            name="machines[]"
            control={control}
            render={({ field }) => (

              <UiTagInput
                {...field}
                className="ss"
                message={errors.machines?.message}
                label="Machines"
                placeholder="Type something and press enter..."


              />

            )}
          />


          <hr></hr>

          <h4><b>Birthday SMS</b></h4>

          <UiTextArea

            label="SMS Content"
            name="birthday_sms"
            message={errors.birthday_sms?.message}
            {...register("birthday_sms")}
          />
          <p><b>Variables: </b>#name#</p>
          <div className="row">
            <div className="col-6">
              <Form.Group className="mb-3" >
                <Form.Label>DLT ID</Form.Label>
                <Form.Control type="text" name="birthday_dltid"  {...register("birthday_dltid")} />
                <Form.Text className="text-danger">
                  {errors.birthday_dltid?.message}
                </Form.Text>
              </Form.Group>
            </div>
            <div className="col-6">
              <UiSelect
                name="birthday_unicode"
                message={errors.birthday_unicode?.message}
                options={[{ 'value': '0', 'label': 'English' }, { 'value': '1', 'label': 'Hindi' }]}
                label="Language"
                {...register(`birthday_unicode`)}
              />
            </div>
          </div>


          <hr></hr>
          <h4><b>Birthday WhatsApp</b></h4>

          <UiTextArea

            label="WhatsApp Content"
            name="birthday_whatsapp"
            message={errors.birthday_whatsapp?.message}
            {...register("birthday_whatsapp")}
          />
          <p><b>Variables: </b>#name#</p>

          <hr></hr>

          <h4><b>Wedding Anniversary SMS</b></h4>

          <UiTextArea

            label="SMS Content"
            name="wedding_sms"
            message={errors.wedding_sms?.message}
            {...register("wedding_sms")}
          />
          <p><b>Variables: </b>#name#</p>
          <div className="row">

            <div className="col-6">
              <Form.Group className="mb-3" >
                <Form.Label>DLT ID</Form.Label>
                <Form.Control type="text" name="wedding_dltid"  {...register("wedding_dltid")} />
                <Form.Text className="text-danger">
                  {errors.wedding_dltid?.message}
                </Form.Text>
              </Form.Group>
            </div>
            <div className="col-6">
              <UiSelect
                name="wedding_unicode"
                message={errors.wedding_unicode?.message}
                options={[{ 'value': '0', 'label': 'English' }, { 'value': '1', 'label': 'Hindi' }]}
                label="Language"
                {...register(`wedding_unicode`)}
              />
            </div>
          </div>


          <hr></hr>
          <h4><b>Wedding Anniversary WhatsApp</b></h4>

          <UiTextArea

            label="WhatsApp Content"
            name="wedding_whatsapp"
            message={errors.wedding_whatsapp?.message}
            {...register("wedding_whatsapp")}
          />
          <p><b>Variables: </b>#name#</p>
          <Button variant="primary" type="submit">
            Save
          </Button>


        </form>
      </div>

    </div>
  );
}
