import React, { useState, useEffect, forwardRef } from "react";
import { instance } from "../../axios";
import { TableZero, PageHeader, UiButton, UiRSelect, UiClientSelect, BgLoader, InLoader, UiActioButton, UiActionDropDown, UiInput, UiSelect, Avatar, PageError, Toastcontent } from "../../ui";
import { useForm, Controller } from "react-hook-form";
import { useNavigate, useOutletContext } from "react-router-dom";
import { Link, useLocation } from "react-router-dom"
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useStore } from "../../state.js";
import { Helmet } from "react-helmet";
import { CSVLink, CSVDownload } from "react-csv";
import Swal from "sweetalert2";
import { toast } from "react-toastify";

import { Table } from 'antd';
import { Dropdown, Popconfirm, Menu } from 'antd';
import { MdOutlineMoreVert } from "react-icons/md";


export default function Roles() {
  let navigate = useNavigate();
  const location = useLocation();
  const zstate = useStore((state) => state);
  const setZState = useStore((state) => state.setClients);
  const [tableDisable, setTableDisable] = useState(false);
  const [state, setState] = useState([]);


  const handleDelete = (id) => {
    var tid = id.toString();

    setTableDisable(true)
    instance
      .get("/update_role?delete=" + tid)
      .then(function (response) {
        setTableDisable(false)
        if (response.data.status == 'success') {
          fetchData();
          toast(response.data.msg, { type: "success" });
        } else {
          toast(response.data.msg, { type: "error" });
        }
      })
  }





  const fetchData = () => {

    instance
      .get("/roles.php")
      .then(function (response) {
        setState(response.data)
      })
      .catch(function (error) { })
      .then(function () { });
  }



  const rolesModal = useModal('role');

  const addRole = (id, row) => {
    rolesModal.show({ id: id, data: row }).then((res) => {
      fetchData()
    });
  }



  const items = (e) => [
    {
      key: '1',
      label: <span>Edit</span>,
      onClick: () => addRole(e.id, e),
    },
    {
      key: '2',
      label: (
        <Popconfirm
          title="Delete?"
          description="Are you sure to delete this?"
          onConfirm={() => handleDelete(e.id)}
          okText="Yes"
          cancelText="No"
        >
          <span>Delete</span>
        </Popconfirm>
      ),
    },


  ];



  const columns = [
    {
      title: 'Name',
      width: '130px',
      dataIndex: 'name',
    },

    
 


    {
      title: '',
      fixed: 'right',
      width: '40px',
      render: (text, row) => (
        <div className="tableaction1">
          <Dropdown menu={{
            items: items(row),
          }}
            trigger={['click']}
          >
            <button className='btn btn-light'><MdOutlineMoreVert /></button>


          </Dropdown>

        </div>


      )
    },

  ];


  useEffect(() => {
    fetchData();  
  }, []);










  return (
    <>
      <Helmet>
        <title>
          Roles
        </title>
      </Helmet>
      <div>
        <div>
          <PageHeader title="Roles" >

            <UiButton className="btn btn-primary" onClick={() => addRole('new')} title="Add" icon="add" ></UiButton>


          </PageHeader>


          <div className="position-relative bg-white rounded mb-4">

   



              <Table
                locale={{
                  emptyText: (<TableZero title="Roles" />)
                }}

                size="small"
                columns={columns}
                rowKey={(record) => record.id}
                dataSource={state.items}
                loading={{ spinning: (tableDisable) }}
                scroll={{
                  x: 960,

                }}
              />


          </div>
        </div>
      </div>
    </>
  );
}
