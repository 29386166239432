import { useState, useEffect } from "react";
import NiceModal, { useModal, bootstrapDialog } from "@ebay/nice-modal-react";
import BootstrapModal from "react-bootstrap/Modal";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import React from "react";
import { toast } from "react-toastify";
import { instance } from "../axios";
import { useNavigate, useParams } from "react-router-dom";
import { OverlayLoader, UiInput, PageError, BgLoader } from "../ui";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { useStore } from "../state.js";


const Role = NiceModal.create(
  ({ id, subtitle, action, bgColor, note = "", data }) => {
    const [input, setInput] = useState(note);
    const [state, setState] = useState({ count: 0 });
    const [entry, setEntry] = useState([]);
    const modal = useModal();
    const zstate = useStore((state) => state.categories);
    const mstate = useStore((state) => state.machines);
    const setZState = useStore((state) => state.setGroups);
    const schema = yup.object().shape({
      name: yup.string().required("Enter role name"),

    }, []);

    const {
      register,
      handleSubmit,
      control,
      watch,
      unregister,
      formState: { errors },
      reset,
    } = useForm({
      resolver: yupResolver(schema),
    });
    const onSubmitHandler = async (data) => {
      SaveTag(data);
      //reset();
    };
    var SaveTag = (e) => {
      setState({ ...state, loader: true });

      instance({
        method: "post",
        url: "/update_role",
        data: e,
        headers: { "Content-Type": "application/json" },
      })
        .then(function (response) {
          setState({ ...state, loader: false });
          if (response.data.status == "error") {
            toast(response.data.msg, { type: "error" });
          }
          if (response.data.status == "success") {
            toast(response.data.msg, { type: "success" });
            modal.resolve({ resolved: true });
            modal.hide();
          }
        })
        .catch(function (response) { });
    };


    const fetchData = () => {
      instance
        .get("/get_role?id=" + id)
        .then(function (response) {
          setState(response.data)
          reset({ name: response.data.name })

        })
        .catch(function (error) { })
        .then(function () { });
    }




    useEffect(() => {

      fetchData();
      if (id == 'new') {
        reset({ change_password: true, status: true })
      }

    }, []);

    const watchPass = watch("change_password");


    const handleHide = () => {
      modal.hide();
    }

    if (state.status == 'error') {
      return (
        <PageError msg={state.msg} code={state.code} />
      )
    }


    return (
      <BootstrapModal {...bootstrapDialog(modal)} onHide={() => handleHide()}>
        <BootstrapModal.Header closeButton>
          <BootstrapModal.Title>Role</BootstrapModal.Title>
        </BootstrapModal.Header>
        <OverlayLoader visible={state.loader} />
        <BootstrapModal.Body>
          {state.status ?
            <form id="roleform" onSubmit={handleSubmit(onSubmitHandler)}>

              <UiInput
                label="Name"
                required
                type="text"
                name="name"
                {...register("name")}
                message={errors.name?.message}
              />




              {state.roles && state.roles.map((val, i) => (
                <div className="mb-3" key={i}>

                  <b>{val.title}</b>
                  <div className="d-md-flex my-2">
                  {val.perm.map((val1, i1) => (
                    <div className={`me-3 d-flex`} key={i1}>
                      {val1.sub == 1 ? <>

                      </> : <div className="d-flex align-items-sm-center flex-column flex-sm-row">
                        <div className="py-1">
                          <input type="checkbox" className="form-check-input" id={val1.id} name={`perm.[${val1.id}].val`} defaultChecked={val1.val} {...register(`perm.[${val1.id}].val`)} />
                          <label className="form-check-label ms-2 user-select-none " htmlFor={val1.id}>{val1.title}</label>
                          <input type="hidden" defaultValue={val1.id} name={`perm.[${val1.id}].id`} {...register(`perm.[${val1.id}].id`)} />
                        </div>

                      </div>}

                    </div>
                  ))}
                  </div>

                </div>
              ))}






              <input type="hidden" value={id} name="id" {...register("id")} />
              <Button variant="primary" type="submit">
                Save
              </Button>
            </form>
            : <BgLoader />}
        </BootstrapModal.Body>

      </BootstrapModal>
    );
  }
);

export default Role;
