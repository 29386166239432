import React, { useState, useEffect } from "react";
import { Outlet, useLocation, NavLink } from "react-router-dom";
import { AiOutlineUser } from 'react-icons/ai';
import { IoIosArrowForward } from 'react-icons/io';
import { instance } from "./axios";
import { useStore } from "./state.js";
import Dropdown from 'react-bootstrap/Dropdown';
import Accordion from 'react-bootstrap/Accordion';
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { TabContent } from "react-bootstrap";
import zustand from "zustand";
import { Logout } from "./helper";
import { GiHamburgerMenu } from "react-icons/gi";


const Layout = () => {
  let location1 = useLocation()

  const zstate = useStore((state) => state.groups);
  const setZState = useStore((state) => state.setGroups);
  const setMState = useStore((state) => state.setMachines);
  const userModal = useModal('addphonebook');
  const setSender = useStore((state) => state.setSenderid);
  const [state, setState] = useState({});
  const [menu, setMenu] = useState(0);


  const showModal = (row) => {
    userModal.show({ data: row }).then((res) => {
      fetchData();
    });


  }


  const fetchData = () => {
    instance
      .get("/home.php")
      .then(function (response) {
        setState(response.data)
        setZState(response.data.groups)
        setMState(response.data.machines)
        setSender(response.data.senderid)
      })
      .catch(function (error) { })
      .then(function () { });
  }

  const { auth, setAuth } = useStore((state) => state);
  let activeStyle = {
    textDecoration: "underline",
  };
  useEffect(() => {

    fetchData()

  }, []);
  useEffect(() => {
    if (menu == 1) {
      setMenu(0)
    }
    //
  }, [location1]);
  const location = useLocation();

  const getNavLinkClass = path => {
    return path.includes(location.pathname)
      ? " show"
      : "";
  };

  const showhideMenu = (e) => {
    if (menu == 1) {
      setMenu(0);
    } else {
      setMenu(1);
    }
  }
  return (
    <>
      <header className="navbar navbar-light sticky-top bg-light flex-md-nowrap py-0 shadow-sm px-3">
        <a className="navbar-brand col-md-3 col-lg-2 me-0 px-3 fs-6" href="#">
          Phonebook
        </a>

        <div className="d-md-none">
          <input type="checkbox" className="btn-check" id="btn-check-outlined" defaultValue={menu} onChange={(e) => showhideMenu(e)} />
          <label className="btn btn-outline-primary" for="btn-check-outlined"><GiHamburgerMenu /> Menu</label>

        </div>
        <Dropdown>
          <Dropdown.Toggle variant="default" id="dropdown-basic">
            User
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <NavLink className="dropdown-item" to="/change-password">Change Password</NavLink>
            <Dropdown.Item onClick={() => Logout()}>Logout</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </header>

      <div className="container-fluid">
        <div className="row">
          <nav
            id="sidebarMenu"
            className={`col-md-3 col-lg-2 d-md-block bg-light sidebar ${menu == '1' ? 'd-block' : 'd-none'}`}
          >
            <div className="position-sticky pt-3 sidebar-sticky">
              <div className="main-menu-item">






                <div className={'nav-treeview p-2  ' + getNavLinkClass(["/profile", "/"])} id="collapseOne">
                  <div className="list-group mb-3">
                    {state.send_msg == 1 &&
                      <NavLink to="/send" className="list-group-item" >Send Messages</NavLink>
                    }
                    {state.master == 1 &&
                      <>
                        <NavLink to="/settings" className="list-group-item">Settings</NavLink>
                        <NavLink to="/users" className="list-group-item">Users</NavLink>
                        <NavLink to="/roles" className="list-group-item">Roles</NavLink>
                      </>}

                  </div>
                  <Accordion defaultActiveKey="0">
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>Phonebook</Accordion.Header>
                      <Accordion.Body>
                        <div className="list-group list-group-flush mb-3">
                         
                            <NavLink to={`/phonebook/all/`} className="list-group-item">All Contacts</NavLink>
                          
                          {zstate && zstate.map((val, i) => (
                            <NavLink key={i}
                              to={`/phonebook/${val.value}`}
                              className="list-group-item"
                            >
                              {val.label}
                            </NavLink>
                          ))}




                        </div>
                        {state.is_admin == 1 &&
                        <button className="btn btn-outline-primary w-100" onClick={() => showModal()}>Add new</button>
}
                      </Accordion.Body>
                    </Accordion.Item>

                  </Accordion>
                </div>
              </div>
            </div>
          </nav>

          <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
            <section className="content d-inline-block w-100">
              <div className="container-fluid">
                <Outlet />
              </div>
            </section>
          </main>
        </div>
      </div>
    </>
  );
};

export default Layout;
