import { useState, useEffect, useRef } from "react";
import NiceModal, { useModal, bootstrapDialog } from "@ebay/nice-modal-react";
import BootstrapModal from "react-bootstrap/Modal";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import React from "react";
import { toast } from "react-toastify";
import { instance } from "../axios";
import { useNavigate, useParams } from "react-router-dom";
import jsPDF from 'jspdf';
import { useReactToPrint } from 'react-to-print';

import Form from 'react-bootstrap/Form';
import { Button } from 'react-bootstrap';
import Select, { StylesConfig } from "react-select";
import { UiButton } from "../ui";

const PrintContacts = NiceModal.create(
  ({ data }) => {
    const [state, setState] = useState({});
    const modal = useModal();


    const contentToPrint = useRef(null);
    const handlePrint = useReactToPrint({
      documentTitle: "Print This Document",
      onBeforePrint: () => console.log("before printing..."),
      onAfterPrint: () => console.log("after printing..."),
      removeAfterPrint: true,
    });


    let navigate = useNavigate();

    const handleHide = () => {
      modal.hide();

    }
    const generatePDF = () => {
      const doc = new jsPDF('p', 'mm', 'a4');
  
      const element = document.getElementById('printdiv');
  
      doc.html(element, {
        callback: function (doc) {
          //doc.save('table.pdf');
          window.open(doc.output('bloburl', 'p&l.pdf'), '_blank');
        },
        x: 10,
        y: 10,
        width: 190,
        windowWidth: 830,
        autoPaging: true, // Automatically manage page breaks
      });
    };

    return (
      <BootstrapModal {...bootstrapDialog(modal)} onHide={() => handleHide()} fullscreen>
        <BootstrapModal.Header closeButton>
          <BootstrapModal.Title>Contacts

            <UiButton className="btn btn-primary ms-2" onClick={() => { handlePrint(null, () => contentToPrint.current); }} title="Print" />
          </BootstrapModal.Title>
        </BootstrapModal.Header>

        <BootstrapModal.Body>
          {data &&
            <div className="bg-white" ref={contentToPrint} id="printdiv" >

              <div className="m-1">
                <h2 className="text-center">{data.group.name}</h2>
                <table className="table table-bordered w-100" style={{ fontSize: '13px' }}>
                  <tbody>
                    {data.items && data.items.map((item, i) => (
                      <tr style={{ breakInside: 'avoid' }} className={`${item[10] == 0 && 'bg-light'}`}>
                        <td>{item[0]}</td>
                        <td>{item[1]}</td>
                        <td>{item[2]}</td>
                        <td>{item[3]}</td>
                        <td>{item[5]}</td>

                        <td>{item[8]}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          }
        </BootstrapModal.Body>

      </BootstrapModal>
    );
  }
);

export default PrintContacts;
