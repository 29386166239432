import { useState, useEffect } from "react";
import NiceModal, { useModal, bootstrapDialog } from "@ebay/nice-modal-react";
import BootstrapModal from "react-bootstrap/Modal";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import React from "react";
import { toast } from "react-toastify";
import { instance } from "../axios";
import { useNavigate, useParams } from "react-router-dom";
import { UiTextArea, UiTagInput, UiSelect } from "../ui";

import Form from 'react-bootstrap/Form';
import { Button } from 'react-bootstrap';
import Select, { StylesConfig } from "react-select";

const GroupReminder = NiceModal.create(
  ({ id }) => {
    const [state, setState] = useState({});
    const modal = useModal();
    const schema = yup.object().shape({
      //name: yup.string().required("Enter group name"),

    });
    const {
      register,
      handleSubmit,
      control,
      formState: { errors },
      reset,
    } = useForm({
      resolver: yupResolver(schema),
    });
    const onSubmitHandler = async (data) => {
      SaveTag();
      //reset();
    };
    var SaveTag = (e) => {
      setState({ ...state, loader: true });
      var form = document.querySelector("#grpformu");
      var data = new FormData(form);

      instance({
        method: "post",
        url: "/update_greeting_setting.php",
        data: data,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then(function (response) {
          setState({ ...state, loader: false });
          if (response.data.status == "error") {
            toast(response.data.msg, { type: "error" });
          }
          if (response.data.status == "success") {
            toast(response.data.msg, { type: "success" });
            modal.resolve({ resolved: true, name: response.data.name });
            modal.hide();
          }
        })
        .catch(function (response) { });
    };


    const fetchData = () => {
      instance
        .get("/get_greeting_setting?id=" + id)
        .then(function (response) {
          setState(response.data)
          reset(response.data.item)

        })
        .catch(function (error) { })
        .then(function () { });
    }


    useEffect(() => {

      if (id) {
        fetchData() 
      }


    }, [id]);


    let navigate = useNavigate();

    const handleHide = () => {
      modal.hide();

    }
    return (
      <BootstrapModal {...bootstrapDialog(modal)} onHide={() => handleHide()}>
        <BootstrapModal.Header closeButton>
          <BootstrapModal.Title>Greeting</BootstrapModal.Title>
        </BootstrapModal.Header>

        <BootstrapModal.Body>
          <form id="grpformu" onSubmit={handleSubmit(onSubmitHandler)}>
            <h4><b>Birthday WhatsApp</b></h4>

            <UiTextArea

              label="WhatsApp Content"
              name="bday_msg"
              message={errors.bday_msg?.message}
              {...register("bday_msg")}
            />
            <p><b>Variables: </b>#name#</p>

            <hr></hr>

            <h4><b>Wedding WhatsApp</b></h4>

            <UiTextArea

              label="WhatsApp Content"
              name="wedding_msg"
              message={errors.wedding_msg?.message}
              {...register("wedding_msg")}
            />
            <p><b>Variables: </b>#name#</p>
            {id && (
              <input type="hidden" value={id} name="id" {...register("id")} />
            )}
            <Button variant="primary" type="submit">
              Save
            </Button>
          </form>
        </BootstrapModal.Body>

      </BootstrapModal>
    );
  }
);

export default GroupReminder;
