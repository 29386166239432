import React, { useState, useEffect, useReducer } from "react";
import { instance } from "../../axios";
import { UiSelect, UiDatePicker, UiRSelect } from "../../ui";
import { useNavigate, useParams } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import DataTable from 'react-data-table-component';
import { AiOutlineClose, AiOutlineSave } from "react-icons/ai";
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { GoTrashcan, GoPencil } from "react-icons/go";
import { toast } from "react-toastify";
import { useStore } from "../../state.js";
import Swal from "sweetalert2";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";


export default function PhoneBook() {
  let { id } = useParams();
  let navigat = useNavigate();
  const [state, setState] = useState([]);
  const [page, setPage] = useState(1);
  const [edit, setEdit] = useState([]);
  const zstate = useStore((state) => state.groups);
  const setZState = useStore((state) => state.setGroups);
  const mstate = useStore((state) => state.machines);
  const userModal = useModal('addcontact');
  const showAddModal = (row) => {
    userModal.show({ data: row }).then((res) => {
      fetchData()
    });
  }


  const schema = yup.object().shape({

  });
  const {
    register,
    handleSubmit,
    control,
    unregister,
    getValues,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });
  const onSubmitHandler = async (data) => {
    setPage(1)
    fetchData();
    //reset();
  };


  const importModal = useModal('importcontacts');
  const importcModal = (row) => {
    importModal.show({ data: row }).then((res) => {
      setPage(1)
      fetchData()
    });
  }



  const handleDelete = (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        instance
          .get("/delete_group.php?id=" + id)
          .then(function (response) {
            if (response.data.status == 'success') {

              toast(response.data.msg, { type: "success" });
              var arr = zstate.slice()

              var arr1 = arr.filter(x => x.value !== id)
              setZState(arr1)
              navigat("/phonebook/all", { replace: true });
            } else {
              toast(response.data.msg, { type: "error" });
            }


          })

      }
    })


  }



  var contactDelete = (idd) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        instance({
          method: "GET",
          url: "/delete_contact.php?id=" + idd,

        })
          .then(function (response) {
            if (response.data.status == "error") {
              toast(response.data.msg, { type: "error" });
            }
            if (response.data.status == "success") {
              toast(response.data.msg, { type: "success" });
              var arr = state.contacts.slice();
              var arr1 = arr.filter(x => x.id !== idd);
              setState({ ...state, contacts: arr1, total: state.total - 1 })
            }
          })


      }
    })

  };


  const changeName = (id, txt) => {

    var arr = state.slice();
    arr.filter(x => x.id == id)[0].ename = txt


    setState(arr)

  }
  const editName = (id) => {

    var arr = state.slice();
    arr.filter(x => x.id == id)[0].editname = 1
    var arr1 = arr.filter(x => x.id == id)
    arr.filter(x => x.id == id)[0].ename = arr1.name;

    setState(arr)

  }
  const columns = [
    {
      name: 'Sr.',
      width: '80px',
      selector: (row, idx) => ((page - 1) * 20) + (idx + 1),
    },
    {
      name: 'Name',
      selector: row => row.name,
      width: '200px',
      cell: row => (<div className="text-truncate">
        {row.editname == 1 ? (
          <InputGroup className="mb-3">

            <Form.Control type="text" defaultValue={row.name} onChange={(e) => changeName(row.id, e.target.value)} placeholder="Enter name" />
            <Button variant="outline-secondary">
              <AiOutlineClose />
            </Button>
            <Button variant="outline-secondary">
              <AiOutlineSave />
            </Button>
          </InputGroup>


        ) : (
          <> {row.name} {row.name1 && (<>({row.name1})</>)}</>
        )}
      </div>)
    },

    {
      name: 'Mobile',
      width: '140px',
      selector: row => row.mobile,
    },
    {
      name: 'Contact 2',
      width: '150px',
      selector: row => row.name2,
      cell: row => (
        <><div className="text-truncate">
          {row.name2}
        </div>
        </>
      )
    },
    {
      name: 'Mobile 2',
      width: '140px',
      selector: row => row.mobile2,
    },
    {
      name: 'Address',
      selector: row => row.address,
      width: '160px',
      cell: row => (
        <><div className="text-truncate">
          {row.address}{row.city && (<> ({row.city})</>)}
        </div>
        </>
      )
    },

    {
      name: 'DOB',
      width: '100px',
      selector: row => row.dob,
    },
    {
      name: 'Anniversary',
      width: '100px',
      selector: row => row.wedding,
    },

    {
      name: 'Machines',
      selector: row => row.machines,
      cell: row => (
        <>
          <div className="text-truncate">
            {row.machines && row.machines.map((val, i) => (
              <span className="bg-light p-1 rounded me-2" key={i}>{val.machine} x {val.qty}</span>
            ))}
          </div>
        </>
      )
    },

    {
      name: 'Group',
      selector: row => row.groups,
      width: '100px',
      cell: row => (
        <>
          <div className="text-truncate">
            {row.groups.map((val, i) => (
              <span className="badge text-bg-light" key={i}>{val.label}</span>
            ))}
          </div>
        </>
      )
    },
    {
      name: 'Action',
      selector: row => row.id,
      cell: row => (
        <>
          {state.can_edit == 1 &&
            <button className="btn btn-default" onClick={() => showAddModal(row)}>
              <GoPencil />
            </button>
          }
          {state.can_delete == 1 &&
            <button className="btn btn-default text-danger" onClick={() => contactDelete(row.id)}>
              <GoTrashcan />
            </button>
          }
        </>
      )
    },
  ];


  const handlePageChange = (pg) => {
    console.log(pg);
    setPage(pg)

  };


  const fetchData = () => {
    const myParams = getValues()

    const u = new URLSearchParams(myParams).toString();


    instance
      .get("/phonebook.php?id=" + id + "&page=" + page + "&" + u)
      .then(function (response) {
        setState(response.data)
      })
      .catch(function (error) { })
      .then(function () { });
  }


  useEffect(() => {
    fetchData()

  }, [id, page]);

  const clearFilter = () => {
    reset()
    setPage(1)
    fetchData()
  }

  const editgrpModal = useModal('addphonebook');



  const grpModal = (row) => {
    editgrpModal.show({ data: row }).then((res) => {
      if (res.name) {
        var arr = zstate

        arr.filter(x => x.value == row.id)[0].label = res.name

        setZState(arr)
        var arr1 = state.group
        arr1.name = res.name
        setState({ ...state, group: arr1 })
      }
    });


  }



  const printModal = useModal('print');

  const printContacts = (row) => {
    printModal.show({ data: row }).then((res) => {
    });
  }

  const greetingModal = useModal('greeting');

  const greetingSetting = (row) => {
    greetingModal.show({ id: row }).then((res) => {
    });
  }


  const ExpandedComponent = ({ data }) => <div className="border rounded p-3">
    <p><b>Name:</b> {data.name} | <b>Contact Person Name:</b> {data.name1}<br></br>
      <b>Contact Person Name 2:</b> {data.name2} | <b>Mobile 2:</b> {data.mobile2}<br></br>
      <b>Address:</b> {data.address} - {data.city}<br></br>
      <b>Machines:</b> {data.machines && data.machines.map((val, i) => (
        <span className="bg-light p-1 rounded me-2" key={i}>{val.machine} x {val.qty}</span>
      ))}<br>
      </br>
      <b>Group:</b> {data.groups.map((val, i) => (
        <span className="badge text-bg-light" key={i}>{val.label}</span>
      ))}</p>

  </div>;



  const exportCSV = () => {
    const myParams = getValues()

    const u = new URLSearchParams(myParams).toString();


    instance
      .get("/phonebook.php?csv=1&id=" + id + "&page=" + page + "&" + u)
      .then(function (response) {
        if (response.data.status == 'success') {
          const url = response.data.link;
          const name = 'export.csv'
          fetch(url)
            .then(response1 => response1.blob())
            .then(blob => {
              const blobURL = URL.createObjectURL(blob);
              const a = document.createElement("a");
              a.href = blobURL;
              a.style = "display: none";

              if (name && name.length) a.download = name;
              document.body.appendChild(a);
              a.click();
            })
        }
      })
  }

  const printAll = () => {
    const myParams = getValues()

    const u = new URLSearchParams(myParams).toString();


    instance
      .get("/phonebook.php?print=1&id=" + id + "&page=" + page + "&" + u)
      .then(function (response) {
        if (response.data.status == 'success') {
          printContacts(response.data)
        }
      })
  }
  return (
    <div className="pt-4">
      {state.group && (
        <>
          <h2 className="mb-3">{state.group.name}  {(state.group.name != 'All Contacts' && state.is_admin == 1) && <button className="btn btn-default" onClick={() => grpModal(state.group)}>
            <GoPencil />
          </button>}</h2>
          <div className="p-3 rounded bg-white">
            {state.can_add == 1 &&
              <button className="btn btn-success" onClick={() => showAddModal()}>Add Contact</button>
            }

            {state.group.name != 'All Contacts' && (
              <>
                {state.can_add == 1 &&
                  <button className="btn btn-primary ms-2" onClick={() => importcModal(state.group.id)}>Import Contacts</button>
                }
                {state.is_admin == 1 &&
                  <button className="btn btn-danger ms-2" onClick={() => handleDelete(state.group.id)}>Delete Group</button>
                }
              </>
            )}
            {state.can_export == 1 &&
              <>
                <button className="btn btn-primary ms-2" onClick={() => exportCSV(state.group.id)}>Export Contacts</button>
                <button className="btn btn-primary ms-2" onClick={() => printAll(state.group.id)}>Print Contacts</button>
              </>
            }
            <button className="btn btn-primary ms-2" onClick={() => greetingSetting(state.group.id)}>Greeting</button>
            <div className="border rounded p-3 mt-3 shadow mb-3">
              <form id="filterform" onSubmit={handleSubmit(onSubmitHandler)}>
                <div className="row">
                  <div className="col-md-3">
                    <Form.Group className="mb-3" >
                      <Form.Label>Search</Form.Label>
                      <Form.Control type="text" name="search"  {...register("search")} />

                    </Form.Group>
                  </div>
                  <div className="col-md-3">
                    <Form.Group className="mb-3" >
                      <Form.Label>City</Form.Label>
                      <Form.Control type="text" name="city"  {...register("city")} />

                    </Form.Group>
                  </div>

                  <div className="col-md-3">
                    <UiSelect
                      name="machine"
                      label="Machine"
                      options={mstate}
                      {...register(`machine`)}
                    />
                  </div>
                  <div className="col-md-3 pt-4">
                    <Button variant="primary" className="mt-2" type="submit">
                      Filter
                    </Button>
                    <Button variant="secondary" onClick={() => clearFilter()} className="mt-2 ms-3">
                      Clear
                    </Button>
                  </div>
                </div>
              </form>
            </div>

            <DataTable
              columns={columns}
              data={state.contacts}

              paginationDefaultPage={page}
              pagination
              paginationServer
              paginationPerPage="20"

              paginationTotalRows={state.total}

              persistTableHead
              paginationComponentOptions={{ noRowsPerPage: true }}

              onChangePage={handlePageChange}

              expandableRows
              //expandableRowExpanded={row => row}
              expandableRowsComponent={ExpandedComponent}
            />
          </div>
        </>
      )}
    </div>
  );
}
