import { useState, useEffect } from "react";
import NiceModal, { useModal, bootstrapDialog } from "@ebay/nice-modal-react";
import BootstrapModal from "react-bootstrap/Modal";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import React from "react";
import { toast } from "react-toastify";
import { instance } from "../axios";
import { useNavigate, useParams } from "react-router-dom";
import { UiSelect, UiInput, UiToggle, BgLoader, TableZero, UiButton, OverlayLoader } from "../ui";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { useStore } from "../state.js";
import { Table } from 'antd';

const User = NiceModal.create(
  ({ id, subtitle, action, bgColor, note = "", data }) => {
    const [input, setInput] = useState(note);
    const [state, setState] = useState({ count: 0 });
    const [entry, setEntry] = useState([]);
    const modal = useModal();
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const schema = yup.object().shape({
      name: yup.string().required("Enter user's full name"),
      username: yup.string().required("Enter login username"),
      role: yup.string().required("Select role"),
      status: yup.bool(),

      mobile: yup.string().nullable().matches(/^[0-9]+$/, "Enter 10 digit mobile number")
        .min(10, "Enter 10 digit mobile number")
        .max(10, "Enter 10 digit mobile number"),



      password: yup.string().when('change_password', {
        is: true,
        then: yup.string().required("Please enter password")
          .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
            "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
          )
      }),
      password1: yup
        .string()
        .oneOf(
          [yup.ref("password"), null],
          "The password and its confirm are not the same"
        ),
    }, []);

    const {
      register,
      handleSubmit,
      control,
      watch,
      unregister,
      formState: { errors },
      reset,
    } = useForm({
      resolver: yupResolver(schema),
    });
    const onSubmitHandler = async (data) => {
      SaveTag(data);
      //reset();
    };
    var SaveTag = (e) => {
      setState({ ...state, loader: true });
      e = { ...e, groups: selectedRowKeys }
      instance({
        method: "post",
        url: "/update_user",
        data: e,
        headers: { "Content-Type": "application/json" },
      })
        .then(function (response) {
          setState({ ...state, loader: false });
          if (response.data.status == "error") {
            toast(response.data.msg, { type: "error" });
          }
          if (response.data.status == "success") {
            toast(response.data.msg, { type: "success" });
            modal.resolve({ resolved: true });
            modal.hide();
          }
        })
        .catch(function (response) { });
    };


    const fetchData = () => {
      instance
        .get("/get_user?id=" + id)
        .then(function (response) {
          setState(response.data)
          reset(response.data.item)
          if(response.data.selected){
            setSelectedRowKeys(response.data.selected)
          }

        })
        .catch(function (error) { })
        .then(function () { });
    }


    const columns = [
      {
        title: 'Group',
        dataIndex: 'name',
      },

    ];

    const onSelectChange = (newSelectedRowKeys) => {
      setSelectedRowKeys(newSelectedRowKeys);
    };
    const rowSelection = {
      selectedRowKeys,
      preserveSelectedRowKeys: true,
      onChange: onSelectChange,
    };

    useEffect(() => {

      fetchData();
      if (id == 'new') {
        reset({ change_password: true, status: true })
      }

    }, []);

    const watchPass = watch("change_password");


    const handleHide = () => {
      modal.hide();
    }




    return (
      <BootstrapModal {...bootstrapDialog(modal)} onHide={() => handleHide()}>
        <BootstrapModal.Header closeButton>
          <BootstrapModal.Title>User</BootstrapModal.Title>
        </BootstrapModal.Header>
        <OverlayLoader visible={state.loader} />
        <BootstrapModal.Body>
          {state.status ?
            <form id="userform" onSubmit={handleSubmit(onSubmitHandler)}>

              <div className="row">
                <div className="col-6">
                  <UiInput
                    label="Name"
                    type="text"
                    required
                    name="name"
                    {...register("name")}
                    message={errors.name?.message}
                  />
                </div>
                <div className="col-6">
                  <UiInput
                    label="Mobile No."
                    type="text"
                    name="mobile"
                    required
                    {...register("mobile")}
                    message={errors.mobile?.message}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-6">

                  <UiInput
                    label="Username"
                    type="text"
                    required
                    disabled={id == 'new' ? false : true}
                    name="username"
                    {...register("username")}
                    message={errors.username?.message}
                  />
                </div>
                <div className="col-6">
                  <UiSelect
                    name='role'
                    label="Role"
                    required
                    message={errors.role?.message}
                    options={state.roles}
                    {...register(`role`)}
                  />
                </div>
              </div>


              <UiToggle
                id="status"
                name="status"
                value="1"
                title="Is Active"
                {...register("status")}
              />







              {id !== 'new' ? (

                <UiToggle
                  id="change_password"
                  name="change_password"
                  title="Change Password"
                  {...register("change_password")}
                />

              ) : (
                <input type="hidden" name="change_password" {...register("change_password")} />
              )}

              <div className="row">
                <div className="col-6">
                  <UiInput

                    required={true}
                    label="Password"
                    type="password"
                    name="password"
                    disabled={watchPass == true ? '' : "disabled"}
                    message={errors.password?.message}
                    {...register("password")}
                  />
                </div>
                <div className="col-6">
                  <UiInput

                    required={true}
                    label="Confirm Password"
                    type="password"
                    name="password1"
                    disabled={watchPass == true ? '' : "disabled"}
                    message={errors.password1?.message}
                    {...register("password1")}
                  />
                </div>
              </div>



              <Table
                locale={{
                  emptyText: (<TableZero title="Groups" />)
                }}

                size="small"
                columns={columns}
                rowKey={(record) => record.id}
                dataSource={state.groups}
                rowSelection={rowSelection}
                pagination={false}
              />





              <input type="hidden" value={id} name="id" {...register("id")} />
              
              <UiButton className="btn btn-primary mt-4" type="submit" title="Save" />

            </form>
            : <BgLoader />}
        </BootstrapModal.Body>

      </BootstrapModal>
    );
  }
);

export default User;
