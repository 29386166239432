import React, { useState, useEffect, useReducer } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { UiDatePicker, UiTextArea, UiRSelect } from "../../ui";
import * as yup from "yup";
import { useNavigate, Link } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import DataTable from 'react-data-table-component';
import { AiOutlineClose, AiOutlineSave } from "react-icons/ai";
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { toast } from "react-toastify";
import { instance } from "../../axios";
import { useStore } from "../../state.js";
import googleTransliterate from "google-input-tool"
import Swal from "sweetalert2";


export default function Send() {
  const zstate = useStore((state) => state.groups);
  const senderidState = useStore((state) => state.senderid);
  const [state, setState] = useState([]);
  const [contact_list, setContacts] = useState([]);
  const [edit, setEdit] = useState([]);
  const [loader, showLoader] = useState(false);
  const [selectableRows, setSelectedRows] = useState([]);
  const [selected_contacts, setSelectedContacts] = useState('');



  let inputLanguage = 'ne-t-i0-und';
  let maxResult = 8;
  let request = new XMLHttpRequest();





  const schema = yup.object().shape({
    type: yup.string().required("Select type"),
    schedule: yup.bool(),

    date: yup.string().when('schedule', {
      is: true,
      then: (rule) => rule.required("Select date")
    }),

    time: yup.string().when('schedule', {
      is: true,
      then: (rule) => rule.required("Select time")
    }),

    dltid: yup.string().when('type', {
      is: (value) => value == 'sms',
      then: (rule) => rule.required("Enter DLT template id")
    }),

    senderid: yup.string().when('type', {
      is: (value) => value == 'sms',
      then: (rule) => rule.required("Enter sender id")
    }),

    sms_content: yup.string().when('type', {
      is: (value) => value == 'sms',
      then: (rule) => rule.required("Required")
    }),

  });

  const columns = [

    {
      name: 'Company',
      width: '200px',
      selector: row => row.name,
    },
    {
      name: 'Name',
      width: '200px',
      selector: row => row.name1,
    },
    {
      name: 'Mobile',
      width: '130px',
      selector: row => row.mobile,
    },


  ];


  const {
    register,
    handleSubmit,
    control,
    unregister,
    watch,
    getValues,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });
  const onSubmitHandler = async (data) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, send it!'
    }).then((result) => {
      if (result.isConfirmed) {
        SaveTag();

      }
    })

    //reset();
  };
  var SaveTag = (e) => {
    showLoader(true);
    var form = document.querySelector("#sendingform");
    var data = new FormData(form);

    instance({
      method: "post",
      url: "/send.php",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then(function (response) {
        showLoader(false);
        if (response.data.status == "error") {
          toast(response.data.msg, { type: "error" });
        }
        if (response.data.status == "success") {
          toast(response.data.msg, { type: "success" });
          reset()

        }
      })
      .catch(function (response) { });
  };


  const handleChange = ({ selectedRows }) => {
    setSelectedRows(selectedRows);
  };

  const TemplateModal = useModal('templates');
  const ShowtemplateList = (row) => {
    TemplateModal.show({ data: row }).then((res) => {
      if (res.temp) {
        reset({ ...getValues(), sms_content: res.temp.content, whatsapp_content: res.temp.content, dltid: res.temp.dltid })
      }
    });
  }

  useEffect(() => {
    setState([{ id: 1, editname: 0, name: 'mukesh', mobile: '98988', group: 'All' }])
    reset({ lang: '0', senderid: senderidState })
  }, []);
  const loadData = () => {


  }
  const stype = watch('type'); // you can also target specific fields by their names
  const s_lang = watch('lang'); // you can also target specific fields by their names
  const sch = watch('schedule'); // you can also target specific fields by their names
  const w_groups = watch('groups[]'); // you can also target specific fields by their names


  useEffect(() => {

    if (w_groups) {
      var xx = []
      w_groups.forEach(element => {
        xx.push(element.value)
      });
      showLoader(true);
      instance
        .get("/fetch_contacts.php?id=" + xx.toString())
        .then(function (response) {
          setContacts(response.data.contacts)
          showLoader(false);
        })
        .catch(function (error) { })
        .then(function () { });


    }
  }, [w_groups]);


  useEffect(() => {
    var xx = []
    if (selectableRows) {
      selectableRows.forEach(element => {
        xx.push(element.value)
      });
      setSelectedContacts(xx.toString())
    } else {
      setSelectedContacts('')
    }


  }, [selectableRows]);

  const typeHindi = (e, fld) => {
    if (s_lang == 1) {
      if (e.keyCode === 32) {
        var endPos = e.target.selectionEnd;
        var fullval = e.target.value.substring(0, endPos)
        var myArray = fullval.split(" ");
        var lastItem = myArray[myArray.length - 2];

        googleTransliterate(request, lastItem, inputLanguage, maxResult)
          .then(function (response) {
            let res = e.target.value.replace(lastItem, response[0][0]);
            if (fld == 'wp') {
              reset({ ...getValues(), whatsapp_content: res })
            } else {
              reset({ ...getValues(), sms_content: res })
            }

            //console.log('Transliterated Text: ', res);
          });


      }
    }
  }

  return (
    <div className="pt-4 position-relative">
      <h2 className="mb-3">Send Message</h2>
      {loader == true && <div className="loaderbox"><div className="spinner-border" role="status">
        <span className="visually-hidden">Loading...</span>
      </div></div>}
      <div className="p-3 rounded bg-white">

        <form id="sendingform" onSubmit={handleSubmit(onSubmitHandler)}>


          <Controller
            name="groups[]"
            control={control}
            render={({ field }) => (

              <UiRSelect
                {...field}
                options={zstate}
                className="ss"
                isMulti
                message={errors.groups?.message}
                label="Group"
              />

            )}
          />
          <div className="rounded border overflow-auto p-3 mb-3" style={{ height: '300px' }}>
            <DataTable
              columns={columns}
              data={contact_list}

              selectableRows
              onSelectedRowsChange={handleChange}
              persistTableHead

            />

          </div>
          <input type="hidden" name="selected" value={selected_contacts} />
          <UiTextArea

            label="Mobile Number"
            name="mobiles"
            message={errors.mobiles?.message}
            {...register("mobiles")}
          />

          <div className="d-inline-block">
            <Form.Check type="radio" aria-label="radio2" id="ss" size="lg" name="lang" value="0" label="English" {...register("lang")} />
          </div><div className="d-inline-block ms-3"><Form.Check type="radio" aria-label="radio2" id="ss1" size="lg" name="lang" value="1" label="Unicode" {...register("lang")} />
          </div>
          <hr></hr>
          <div className="border d-inline-block px-2 pt-1 rounded bg-light shadow">
            <Form.Check type="radio" aria-label="radio1" id="sms" size="lg" name="type" value="sms" label="SMS" {...register("type")} />
          </div>
          <div className="ms-2 border d-inline-block px-2 pt-1 rounded bg-light shadow">
            <Form.Check type="radio" aria-label="radio1" id="wp" name="type" size="lg" value="wp" label="WhatsApp" {...register("type")} />
          </div>
          <hr></hr>


          {stype && stype == 'sms' && (
            <div>
              <Form.Group className="mb-3" >
                <Form.Label>Sender ID</Form.Label>
                <Form.Control type="text" name="senderid"  {...register("senderid")} />
                <Form.Text className="text-danger">
                  {errors.senderid?.message}
                </Form.Text>
              </Form.Group>
              <UiTextArea
                onKeyUp={(e) => typeHindi(e, 'sms')}
                label="SMS Content"
                name="sms_content"
                message={errors.sms_content?.message}
                {...register("sms_content")}
              />
              <p><b>Variables: </b>#name#, #company#</p>

              <Form.Group className="mb-3" >
                <Form.Label>DLT ID</Form.Label>
                <Form.Control type="text" name="dltid"  {...register("dltid")} />
                <Form.Text className="text-danger">
                  {errors.dltid?.message}
                </Form.Text>
              </Form.Group>

              <a className="btn btn-secondary mb-3" onClick={() => ShowtemplateList()}>Templates</a>
            </div>
          )}

          {stype && stype == 'wp' && (
            <div>

              <UiTextArea
                onKeyUp={(e) => typeHindi(e, 'wp')}

                label="Message"
                name="whatsapp_content"
                message={errors.whatsapp_content?.message}
                {...register("whatsapp_content")}
              />
              <p><b>Variables: </b>#name#, #company#</p>
              <Form.Group controlId="formFile" className="mb-3">
                <Form.Label>Select File</Form.Label>
                <Form.Control name="file" accept=".pdf,.jpg,.jpeg,.png,.gif,.mov,.mp4" type="file" {...register("file")} />
                <Form.Text className="text-danger">
                  {errors.file?.message}
                </Form.Text>
              </Form.Group>
              <a className="btn btn-secondary mb-3" onClick={() => ShowtemplateList()}>Templates</a>
            </div>
          )}




          {stype &&

            <>
              <div className="mb-3">
                <Form.Check type="checkbox" aria-label="radio1w" id="wpww" name="schedule" size="lg" label="Schedule" {...register("schedule")} />
                {sch == true && (
                  <>
                    <div className="row mt-3">
                      <div className="col-md-3">
                        <Controller
                          name="date"
                          control={control}
                          render={({
                            field: { onChange, onBlur, value, name, ref },
                            fieldState: { invalid, isTouched, isDirty, error },
                            formState,
                          }) => (
                            <UiDatePicker
                              label="Schedule Date"
                              onChange={onChange}
                              default_val={value}
                              message={errors.date?.message}
                              dateFormat="dd-MM-yyyy"
                              ref={ref}
                              name={name}
                            />
                          )}
                        />
                      </div>
                      <div className="col-md-3">
                        <Controller
                          name="time"
                          control={control}
                          render={({
                            field: { onChange, onBlur, value, name, ref },
                            fieldState: { invalid, isTouched, isDirty, error },
                            formState,
                          }) => (
                            <UiDatePicker
                              label="Schedule Time"
                              onChange={onChange}
                              default_val={value}
                              message={errors.time?.message}

                              ref={ref}
                              name={name}
                              timeFormat="HH:mm"
                              showTimeSelect
                              showTimeSelectOnly
                              timeIntervals={60}
                              timeCaption="Time"
                              dateFormat="HH:mm"
                            />
                          )}
                        />
                      </div>
                    </div>
                  </>
                )}
              </div>
              <Button variant="primary" type="submit">
                Send
              </Button>

            </>
          }
        </form>
      </div>

    </div>
  );
}
